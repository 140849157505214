<template>
  <li class="relative">
    <SearchResultCategoryHeader
      v-if="(isMdAndDown && !open) || isMdAndUp"
      :value="value.name"
      :is-big="isBig"
      :open="open"
      class="mb-2"
      @toggle="$emit('toggle')"
    />

    <SearchResultList
      :value="results"
      :show-all="open"
      :preview="preview"
      class="pb-7 md:pb-9"
      @toggle="$emit('toggle')"
    />
    <EmptySearch v-if="needEmpty && !value.repertoire.length" />
    <div v-else class="slider-gradient absolute right-0 top-0 z-10 block h-full w-5 md:hidden" />
  </li>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { SearchResultList, type SearchItem, SearchResultCategoryHeader } from '@/5_entities/Search'
import { useBreakpoint } from '@/6_shared/lib'
import EmptySearch from './EmptySearch.vue'

type PropType = {
  value: SearchItem
  open: boolean
  needEmpty?: boolean
  maxItemsDesktop: number
  maxItemsMobile: number
}
type EmitType = {
  (e: 'toggle'): void
}

const props = withDefaults(defineProps<PropType>(), {
  needEmpty: false
})
defineEmits<EmitType>()
const {
  md: [isMdAndUp, isMdAndDown]
} = useBreakpoint()
const PREVIEW_MIN_LENGTH = 8
const maxItems = computed(() => (isMdAndUp.value ? props.maxItemsDesktop : props.maxItemsMobile))
const results = computed(() => {
  if (props.open) return props.value.repertoire

  return props.value.repertoire.filter((_, index) => index < props.maxItemsDesktop)
})
const isBig = computed(() => props.value.repertoire.length > maxItems.value)
const preview = computed(() => {
  if (props.value.repertoire.length < PREVIEW_MIN_LENGTH) return undefined

  return props.value.repertoire.slice(-3)
})
</script>
