<template>
  <div>
    <ul class="flex flex-col">
      <template v-if="value && value.length">
        <SearchResultsCategory
          v-for="categoryResults in value"
          :key="categoryResults.id"
          :value="categoryResults"
          :open="false"
          :max-items-desktop="maxItemsDesktop"
          :max-items-mobile="maxItemsMobile"
          @toggle="emit('toggle', categoryResults.id)"
        />
      </template>
      <template v-else>
        <EmptySearch />
      </template>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { type Search, type SearchItem } from '@/5_entities/Search'
import EmptySearch from './EmptySearch.vue'
import SearchResultsCategory from './SearchResultsCategory.vue'

type PropType = {
  value?: Search
  maxItemsDesktop: number
  maxItemsMobile: number
}

type EmitType = {
  (e: 'toggle', id: SearchItem['id']): void
}

withDefaults(defineProps<PropType>(), {
  value: undefined
})

const emit = defineEmits<EmitType>()
</script>
